h1, h3 {
    color: white;
    font-weight: 700;
}

h1 {
    font-size: 4.25rem;
    line-height: 1;
}

h3 {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

p {
    color: #999;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: justify;
}

.heading-box {
    margin-top: 12rem;
}

.profile-box {
    position: absolute;
    width: auto;
    z-index: -200;
}

.scrollBtn {
    border-radius: 16px;
    padding: 6px 18px
}

.typist, .typist h1 {
    color: #0f0f0f;
    font-size: 4.75rem;
    line-height: 1em;
    letter-spacing: -0.7px;
    margin-bottom: 7rem;
    position: absolute;
    top: 6.75rem;
    z-index: -100;
}

.m-heading-box {
    margin-right: auto;
    text-align: center;
}

.m-heading-box h1, .m-heading-box .typist h1 {
    font-size: 3.75rem;
    text-align: center;
}

.m-heading-box .typist {
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.m-heading-box p {
    font-size: 0.9rem;
}

.m-profile-box {
    margin: 3rem auto auto;
}

.m-profile-box-img {
    width: 100%;
}