#footer, #footer p {
    text-align: center;
}

#footer p {
    margin-top: 1rem;
}

.social-box i, .coding-box i {
    margin: 20px 20px;
    padding-left: 0.15rem;
    padding-right: 0.15rem;
}

.social-box i {
    color: white;
}

.coding-box i {
    color: darkgray;
}

