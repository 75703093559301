h6 {
    color: #5f99ff;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}

h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 700;
    color: white;
}

.skill-set {
    margin: 8rem 0 9rem 0;
}

.skill-p {
    margin-top: 4rem;
}

.m-heading-box .skill-set {
    margin-bottom: 0;
}