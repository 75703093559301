.carousel-indicators {
    bottom: -3rem;
}

.carousel-indicators .active {
    border: 1px solid transparent;
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 10px;
    background-color: #5f99ff;
    height: 10px;
    width: 10px
}

.carousel .carousel-indicators button {
    border: 1px solid transparent;
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 10px;
    height: 10px;
    width: 10px
}

@media (max-width: 768px) {
    .carousel-control-prev, .carousel-control-next {
        display: none;
    }
}

.card-img-overlay {
    opacity: 0;
    color: black;
    position: absolute;
    transform: translateY(50%);
}

.card-img-overlay h2 {
    display: flex;
    position: absolute;
    transform: translateY(50%);
    top: 25%;
}

.card:hover .card-img-overlay {
    opacity: 1;
    background-image: linear-gradient(0deg, #000, rgba(0, 0, 0, 0.8) 59%, transparent);
    transition: opacity 0.2s ease-in-out;
}

.card:hover {
    transition: all 0.2s ease-in;
}

.carousel-control-prev {
    margin-left: -10rem;
}

.carousel-control-next {
    margin-right: -10rem;
}

.m-carousel-box .card:hover .card-img-overlay {
    display: none;
}