/*.career-row {*/
/*    margin: auto;*/
/*}*/
.career-row h5 {
    display: inline;
    color: white;
    font-weight: 700;
    margin-left: 1rem;
}

.career-row .career-role {
    color: white;
    margin-left: 1rem;
}

.career-row .career-date {
    margin-left: 1rem;
}

.career-cv-btn .btn {
    border-radius: 16px;
    padding: 6px 18px;
    margin-top: 1rem;
}

.career-cv-btn {
    /*text-align: right;*/
}

.m-career-heading-box {
    text-align: center;
}

.career-heading-box {
    display: flex;
    justify-content: space-between;
    margin: 6rem 0 0;
}

.m-career-row {
    margin: 3rem 0;
    display: flex;
    flex-direction: column;
}